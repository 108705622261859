import React, { useEffect, useState } from 'react';

export default () => {
  let localTheme;
  if (typeof window !== 'undefined') {
    const localTheme = window.localStorage.getItem('theme');
  }
  const [theme, setTheme] = useState(localTheme || 'dark');

  const toggleTheme = () => {
    if (theme === 'light') {
      window.localStorage.setItem('theme', 'dark');
      setTheme('dark');
    } else {
      window.localStorage.setItem('theme', 'light');
      setTheme('light');
    }
  }

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme');
    if (localTheme) {
      setTheme(localTheme);
      document.body.className = localTheme;
    }
  }, [theme]);

  return [
    theme,
    toggleTheme,
  ]
}
