import React from "react";
import { Link } from "gatsby";
import Toggle from 'react-toggle';
import Logo from './logo';
import { rhythm, scale } from "../utils/typography";
import useDarkMode from './themer';

const Layout= ({ location, title, description, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  let header;

  const [ theme, toggleTheme ] = useDarkMode();

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>
        {location.pathname === rootPath ? (
          <Link
            style={{
              marginTop: rhythm(1 / 2),
              marginBottom: rhythm(1),
            }}
            to={`/`}
          >
            <Logo title={title} />
          </Link>
        ) : (
          <Link
            style={{
              marginBottom: rhythm(2),
            }}
            className="back"
            to={`/`}
          >
            <svg viewBox="-1 -1 32 22" width="24px" height="16px">
              <title>Back</title>
              <path d="M 10 0 l -10 10 l 10 10" fill="none" stroke="currentColor" strokeWidth="3px" strokeLinecap="round" />
              <path d="M 0 10 l 30 0" className="show" fill="none" stroke="currentColor" strokeWidth="3px" strokeLinecap="round" />
            </svg>
          </Link>
        )}
      </header>
      <div className="toggle-holder">
        <Toggle
          icons={{
            unchecked: (
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="16px" height="16px" viewBox="0 0 512 512">
              	<path d="M257.894 156.948c-53.258 0-96.42 43.561-96.42 97.26 0 53.719 43.161 97.249 96.42 97.249 53.197 0 96.379-43.53 96.379-97.25 0-53.698-43.182-97.26-96.379-97.26zM257.894 309.873c-30.464 0-55.163-24.945-55.163-55.665s24.699-55.624 55.163-55.624c30.423 0 55.101 24.904 55.101 55.624s-24.688 55.665-55.101 55.665z" fill="#FFF"/>
              	<path d="M241.808 43.499h32.144v79.575h-32.144v-79.575z" fill="#FFF"/>
              	<path d="M417.209 115.897l-22.723-22.917-55.757 56.279 22.723 22.907z" fill="#FFF"/>
              	<path d="M389.468 238.407h78.899v32.389h-78.899v-32.389z" fill="#FFF"/>
              	<path d="M396.012 416.86l22.723-22.917-55.767-56.259-22.712 22.897z" fill="#FFF"/>
              	<path d="M242.473 388.915h32.144v79.575h-32.144v-79.575z" fill="#FFF"/>
              	<path d="M96.266 396.073l22.722 22.938 55.778-56.289-22.692-22.928z" fill="#FFF"/>
              	<path d="M43.633 240.537h78.879v32.43h-78.879v-32.43z" fill="#FFF"/>
              	<path d="M115.394 93.051l-22.681 22.907 55.757 56.258 22.702-22.917z" fill="#FFF"/>
              </svg>
            ),
            checked: (
              <svg viewBox="-12 0 448 448.04455" width="16px" height="16px" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m224.023438 448.03125c85.714843.902344 164.011718-48.488281 200.117187-126.230469-22.722656 9.914063-47.332031 14.769531-72.117187 14.230469-97.15625-.109375-175.890626-78.84375-176-176 .972656-65.71875 37.234374-125.832031 94.910156-157.351562-15.554688-1.980469-31.230469-2.867188-46.910156-2.648438-123.714844 0-224.0000005 100.289062-224.0000005 224 0 123.714844 100.2851565 224 224.0000005 224zm0 0"
                  fill="#FFF"
                />
              </svg>
            ),
          }}
          checked={theme === 'dark'}
          onChange={toggleTheme}
          defaultChecked={false}
          aria-label='No label tag'
        />
      </div>
      <main>{children}</main>
      <footer className="footer">
        © {new Date().getFullYear()}
      </footer>
    </div>
  );
}

export default Layout;
